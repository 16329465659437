@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

/* Navbar */

*{
    font-family: "Roboto";
}

main{
    min-height: 87vh !important;
    background: #121212;
}
body{
    background: #121212;
}
a{
  text-decoration: none;
  color: #fff;
  padding: 0 !important;
  margin: 0 ;
}


.accountsettings li>a{
    display: inline-block;
    border-left: 10px solid transparent;
    width: 90%;
    height: 30px;
    line-height: 32px;
}
.accountsettings a:hover{
    color: #fff;
}
.accountsettings li>a:focus {
    background-color: rgb(40, 40, 40);
    border-right: 3px solid lightgray;
    
}
.accountsettings .content {
    min-height: 40vh;
}
.alert-danger{
   padding: 4px;
   margin: 5px 0 5px 0;
}

#basic-nav-dropdown{
    /* color:#00d836; */
    /* font-size: 0.6rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
}

.basic-nav-dropdown::after{
   display: inline-block;
   width: 5px;
  
}

.dropdown-toggle::after{
    font-size: 1rem;
}

div.dropdown-menu{
    padding: 0.2rem 0.4rem ;
}

div.dropdown-menu > a {
    font-size: 1.1rem;
    font-weight: 500;
    background-color: transparent;
}
.dropdown-menu {
    min-width: 6rem;
}
/* Logo */
.memotrack-logo{
    width: 160px;
}

/* Card styling */
.card {
    height: 10rem;
    /* overflow: hidden; */
}
.card-body{
    border-radius: 5px;
}
.djName{
    color: rgb(26, 153, 0);
}
.smallText{
    font-size: 0.7rem;
}
.list-card{
    transition: transform 150ms ease-in-out;
}
.list-card:hover{
    color:#FFF;
    opacity: 0.8;
    transform: scale(1.02);
}
.public-list-card{
    transition: transform  150ms ease-in-out;
}
.public-list-card:hover{
    
    transform: scale(1.02);
}
/* Homepage card */
.home-card.card {
    height: auto;
    /* width: 30%; */
    border-color: #5e5d5d;
}
/* iframe google video */
iframe{
    width: 100%;
    height: 50vh;
}


.user-picture{
    width: 50px;
    height: 50px;
}

/* Comment discussion image */
.user-picture-discussion{
    width: 56px;
}
.user-picture-discussion > img {
    height: 56px;
    border-radius: 50%;
}
.discussion-container{
    background-color:#1F1B24;
}
.user-comment-container{
    color:#bebebe;
    padding: 1.2rem;
}

.user-picture{
    width: 40px;
    height: 40px;
}
.user-picture > img{
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.comment-input .form-control:focus{
    box-shadow:none;
}
/* Hero section Homepage */
.hero-section{
    min-height: 50vh;
    background: #121212;
}

/* Buttons */
/* .btn-pruple, */
.btn-add
/* .btn-signup */
 {
    color: #fff;
    background-color: #6200EE;
    border: none;
}
.btn-add:hover
{
    color: #fff;
    background-color: #4527A0;
}
.btn-createlist{
    background-color: #00796B;
}
.btn-createlist:hover{
    background-color:#4DB6AC; 
}

.trash-icon{
    color: #FF0266;
}
.pen-icon{
    color: #03DAC6;
}
/* Boostrap button hover (override) */
.btn-outline-primary:hover{
    color: #0275d8;
    background-color:transparent;
}

/* Input file styling */
input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.cardImage > img {
    width: 112px;
    height: 112px;
}
.nav-image > img {
    width: 40px;
    height: 40px;
}
.nav-username{
    min-width: 5rem;
}

.card-badge{
    letter-spacing: 0.05rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
}

.list-group-item > span.badge{
    background-color: #6200EE !important;
}